import * as React from "react"
import Layout from "../components/layout"
import Head from '../components/head'
import { Link } from "gatsby"

// markup
const NotFoundPage = () => {
  const pagenotfound = [
    {
      "Meta_Title" : 'Page not found - MSys Technologies',
      "Meta_Description" : '404 Page not found'
    }
  ]
  return (
    <Layout>
      <Head SEO_Tags={pagenotfound[0]} />  
      <section className="BG-F9FAFE section_allWork ">
        <div className="container">
          <h1>404 Page not found</h1>
          <p>Back to <Link exact to="/">Homepage</Link> </p>
        </div>
      </section>
    </Layout>
  )
}

export default NotFoundPage